import { LanguageDto } from '@ecdlink/core';

export const LanguagesModels: LanguageDto[] = [
  {
    id: '7cc62017-7ee7-4f2c-9214-bc9be3f2396a',
    description: 'isiZulu',
    locale: 'zu',
    isActive: true,
  },
  {
    id: '03fff220-106f-4ff7-9e06-20c4ec439483',
    description: 'isiXhosa',
    locale: 'xh',
    isActive: true,
  },
  {
    id: '058b9d8e-e472-48d6-8415-ba9408b95395',
    description: 'Afrikaans',
    locale: 'af',
    isActive: true,
  },
  {
    id: '9688cd08-adef-408c-9d34-5d75ae5c44df',
    description: 'English',
    locale: 'en-za',
    isActive: true,
  },
  {
    id: '06370c67-692e-4664-a90a-c2de0621ff4d',
    description: 'Sepedi',
    locale: 'nso',
    isActive: true,
  },
  {
    id: 'c45fda51-e967-4414-8916-c39895aeb080',
    description: 'Setswana',
    locale: 'tn',
    isActive: true,
  },
  {
    id: '0b86af94-d341-435a-b944-7a8c874c385a',
    description: 'Sesotho',
    locale: 'st',
    isActive: true,
  },
  {
    id: 'b603d6d0-8b50-47ec-af3c-c6a2a078e56b',
    description: 'Xitsonga',
    locale: 'tso',
    isActive: true,
  },
  {
    id: '8de7442b-4c2e-4bc9-8b0f-0a2b3aa27f46',
    description: 'siSwati',
    locale: 'ss',
    isActive: true,
  },
  {
    id: 'e3adeb4f-d4ff-4daf-9e4a-9195181ee412',
    description: 'Tshivenda',
    locale: 've',
    isActive: true,
  },
  {
    id: '9ff6d6ff-4d77-4642-a6c8-9c3d06a40058',
    description: 'isiNdebele',
    locale: 'nr',
    isActive: true,
  },
  {
    id: '1aac8dd2-6860-4e62-bc6a-c37ddf757bbc',
    description: 'Other',
    locale: 'other',
    isActive: true,
  },
];
