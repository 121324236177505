import { useMutation } from '@apollo/client';
import {
  LeagueIdEnum,
  NOTIFICATION,
  PortalLeagueDto,
  useNotifications,
} from '@ecdlink/core';
import { Clinic, AddClinicToLeague } from '@ecdlink/graphql';
import { Alert, Button, Divider, Dropdown, Typography } from '@ecdlink/ui';
import { useCallback, useState } from 'react';

type LeagueId = string;

type AssignedClinics = { [clinicId: string]: LeagueId };

export interface AssignClinicsToALeagueProps {
  unassignedClinics: Clinic[];
  leagues: PortalLeagueDto[];
  onClose: () => void;
  onClinicAssigned?: (assignedClinics: AssignedClinics) => void;
}

export const AssignClinicsToALeague = ({
  unassignedClinics,
  leagues,
  onClose,
  onClinicAssigned,
}: AssignClinicsToALeagueProps) => {
  const [assignedClinics, setAssignedClinics] = useState<AssignedClinics>({});

  const { setNotification } = useNotifications();

  const [addClinicToLeague, { loading: addingClinic }] =
    useMutation(AddClinicToLeague);

  const getDistrictOptions = useCallback(
    (districtId: string) => {
      return (
        leagues
          ?.filter(
            (league) =>
              league.districtId === districtId ||
              league.leagueTypeId === LeagueIdEnum.SuperLeague
          )
          ?.map((league) => ({
            value: league.id,
            label: league.name,
          })) ?? []
      );
    },
    [leagues]
  );

  const onChange = (clinicId: string, leagueId: string) => {
    const updatedObject = { ...assignedClinics, [clinicId]: leagueId };
    setAssignedClinics(updatedObject);
    onClinicAssigned?.(updatedObject);
  };

  const onSave = async () => {
    const clinicPromises = Object.entries(assignedClinics).map(
      ([clinicId, leagueId]) => ({
        clinicId,
        promise: addClinicToLeague({
          variables: {
            clinicId,
            leagueId,
          },
        }),
      })
    );

    try {
      const results = await Promise.allSettled(
        clinicPromises.map((item) => item.promise)
      );

      const failedClinics = [];

      results.forEach((result, index) => {
        if (result.status === 'rejected') {
          failedClinics.push(clinicPromises[index].clinicId);
        }
      });

      if (failedClinics.length > 0) {
        const clinicNames = failedClinics
          .map(
            (clinicId) =>
              unassignedClinics.find((clinic) => clinic.id === clinicId)?.name
          )
          .join(', ');

        const isAllClinicsFailed =
          failedClinics.length === clinicPromises.length;

        setNotification({
          title: isAllClinicsFailed
            ? 'Failed to assign clinics to league'
            : 'Some clinics failed to be added to league!',
          message: isAllClinicsFailed
            ? ''
            : `Failed to assign clinics to league: ${clinicNames}`,
          variant: isAllClinicsFailed ? NOTIFICATION.ERROR : NOTIFICATION.ALERT,
          timeout: 10000,
        });
      } else {
        setNotification({
          title: `${results.length} clinic(s) added to league!`,
          variant: NOTIFICATION.SUCCESS,
        });
      }
    } catch (error) {
      setNotification({
        title: 'Failed to assign clinics to league',
        message: error,
        variant: NOTIFICATION.ERROR,
      });
    } finally {
      onClose();
    }
  };

  return (
    <>
      <Typography type="help" color="textMid" text="Step 1 of 1" />
      <Divider dividerType="dashed" className="mt-5 mb-9" />
      <Alert
        className="rounded-2xl"
        type="warning"
        title="At this stage, clinics can only be added to existing leagues within their district."
      />
      {unassignedClinics?.map((clinic) => (
        <Dropdown
          key={clinic.id}
          placeholder="Select a league"
          fillColor="adminPortalBg"
          className="mt-4"
          label={`Add ${clinic.name} (${
            clinic?.subDistrict?.name ?? ''
          }) to a league`}
          selectedValue={assignedClinics?.[clinic.id]}
          onChange={(leagueId) => onChange(clinic.id, leagueId)}
          list={getDistrictOptions(clinic?.subDistrict?.district?.id ?? '')}
        />
      ))}
      <Button
        className="mt-4 w-full rounded-2xl"
        icon="SaveIcon"
        type="filled"
        color="secondary"
        textColor="white"
        text="Save"
        isLoading={addingClinic}
        disabled={addingClinic}
        onClick={onSave}
      />
    </>
  );
};
