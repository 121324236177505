import icon1 from '../../../../../assets/theme-icons/Avatar-1.svg'
import icon2 from '../../../../../assets/theme-icons/Avatar-2.svg'
import icon3 from '../../../../../assets/theme-icons/Avatar-3.svg'
import icon4 from '../../../../../assets/theme-icons/Avatar-4.svg'
import icon5 from '../../../../../assets/theme-icons/Avatar-5.svg'
import icon6 from '../../../../../assets/theme-icons/Avatar-6.svg'
import icon7 from '../../../../../assets/theme-icons/Avatar-7.svg'
import icon8 from '../../../../../assets/theme-icons/Avatar-8.svg'
import icon9 from '../../../../../assets/theme-icons/Avatar-9.svg'
import icon10 from '../../../../../assets/theme-icons/Avatar-10.svg'
import icon11 from '../../../../../assets/theme-icons/Avatar-11.svg'
import icon12 from '../../../../../assets/theme-icons/Avatar-12.svg'
import icon13 from '../../../../../assets/theme-icons/Avatar-13.svg'
import icon14 from '../../../../../assets/theme-icons/Avatar-14.svg'
import icon15 from '../../../../../assets/theme-icons/Avatar-15.svg'
import icon16 from '../../../../../assets/theme-icons/Avatar-16.svg'
import icon17 from '../../../../../assets/theme-icons/Avatar-17.svg'
import icon18 from '../../../../../assets/theme-icons/Avatar-18.svg'
import icon19 from '../../../../../assets/theme-icons/Avatar-19.svg'
import icon20 from '../../../../../assets/theme-icons/Avatar-20.svg'
import icon21 from '../../../../../assets/theme-icons/Avatar-21.svg'
import icon22 from '../../../../../assets/theme-icons/Avatar-22.svg'
import icon23 from '../../../../../assets/theme-icons/Avatar-23.svg'
import icon24 from '../../../../../assets/theme-icons/Avatar-24.svg'
import icon25 from '../../../../../assets/theme-icons/Avatar-25.svg'
import icon26 from '../../../../../assets/theme-icons/Avatar-26.svg'
import icon27 from '../../../../../assets/theme-icons/Avatar-27.svg'
import icon28 from '../../../../../assets/theme-icons/Avatar-28.svg'
import icon29 from '../../../../../assets/theme-icons/Avatar-29.svg'
import icon30 from '../../../../../assets/theme-icons/Avatar-30.svg'
import icon31 from '../../../../../assets/theme-icons/Avatar-31.svg'
import icon32 from '../../../../../assets/theme-icons/Avatar.svg'

const themeIcons = [
    icon1,
    icon2,
    icon3,
    icon4,
    icon5,
    icon6,
    icon7,
    icon8,
    icon9,
    icon10,
    icon11,
    icon12,
    icon13,
    icon14,
    icon15,
    icon16,
    icon17,
    icon18,
    icon19,
    icon20,
    icon21,
    icon22,
    icon23,
    icon24,
    icon25,
    icon26,
    icon27,
    icon28,
    icon29,
    icon30,
    icon31,
    icon32,
]

export default themeIcons