export const enum MessageStatusConstants {
  Amber = 'amber',
  Blue = 'blue',
  Red = 'red',
  Green = 'green',
}

export const enum NotificationsCTAText {
  AddMeetingReport = '[[AddMeetingReport]]',
  SeeClinicSummary = '[[SeeClinicSummary]]',
  ContactCHW = '[[ContactCHW]]',
  AddLeagues = '[[Add leagues]]',
  AssignToLeagues = '[[Assign to leagues]]',
  CHWsOptedOut = '[[See CHWs]]',
  DuplicateClientPregnantMom = '[[Contact [[CHWFullName]]]]',
  DuplicateClientChild = '[[Contact [[CHWFullName]]]]',
  AdminNextMonthTLMeetingTopic = '[[Add a topic]]',
  ClinicMissingTL = '[[Assign team lead]]',
  NoMeetingReportClinic = '[[Contact [[TeamLeadName]]]]',
}
