import { StatusChip, Typography, ScoreCard } from '@ecdlink/ui';
import { PointsReportSummaryDto } from '../view-clinic-report';
import { calculateTierPercentages, getTierDetails } from './points-utils';
import { LeagueType } from '../view-clinic-report.types';
import { ClinicDto, getCommunityQuarterDescription } from '@ecdlink/core';

interface PointsReportSummaryProps {
  dataFromClinicPointsData: PointsReportSummaryDto;
  clinic?: ClinicDto;
}

export const PointsReportSummary: React.FC<PointsReportSummaryProps> = ({
  dataFromClinicPointsData,
  clinic,
}) => {
  const today = new Date();
  const { quarter } = getCommunityQuarterDescription(today);
  const { bronzePercentage, silverPercentage, goldPercentage } =
    calculateTierPercentages(
      ('Super League' as LeagueType) ?? LeagueType.League
    );

  const { tierName, tierColor } = getTierDetails(
    LeagueType.SuperLeague,
    dataFromClinicPointsData?.pointsTotal ?? 0
  );

  return (
    <>
      <div className="mt-8">
        <Typography
          className="truncate"
          type="h1"
          weight="bold"
          color="textMid"
          text={`Summary`}
        />
        <div className="mt-2 flex w-full items-center gap-2">
          <StatusChip
            backgroundColour="darkBlue"
            borderColour="darkBlue"
            text={`${dataFromClinicPointsData?.totalHCWs} CHWs`}
            textColour={'white'}
            className={'px-4 py-1.5'}
          />
          <StatusChip
            backgroundColour="successMain"
            borderColour="successMain"
            text={`#${dataFromClinicPointsData?.leagueRanking} in the league`}
            textColour={'white'}
            className={'px-4 py-1.5'}
          />
        </div>
      </div>
      <div className="mt-8 w-6/12 rounded-2xl bg-white p-6">
        <div className="bg-pointsCardBg rounded-2xl p-2">
          <ScoreCard
            className="my-4"
            mainText={
              String(dataFromClinicPointsData?.pointsTotal) ?? String(0)
            }
            hint={`points so far in Quarter ${quarter?.quarter}`}
            currentPoints={dataFromClinicPointsData?.pointsTotal ?? 0}
            maxPoints={10000}
            barBgColour="pointsCardBarBg"
            barColour={tierColor}
            bgColour="pointsCardBg"
            barSize="small"
            textColour="black"
            barStatusChip={{
              backgroundColour: 'alertMain',
              borderColour: 'alertMain',
              textColour: 'white',
              text: tierName,
            }}
            barDivides={[
              { widthPercentage: bronzePercentage },
              { widthPercentage: silverPercentage },
              { widthPercentage: goldPercentage },
            ]}
          />
        </div>
      </div>
    </>
  );
};
