export type CoachRouteState = {};

export interface CoachesRouteState {
  //queryVariables: QueryAllHealthCareWorkersArgs;
}

export const ColumnNames = {
  IdPassport: 'ID/Passport',
  Name: 'Name',
  Usage: 'App usage',
  Date: 'Date Added',
  Status: 'Status',
};

export const UserSearch = {
  SearchBy: '      Search by name, ID or cellphone...',
};
