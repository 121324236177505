export const videoExtensions = ['mp4', 'mov', 'mkv', 'm4v', 'mpg', 'webm'];

export enum TenantType {
  ChwConnect = 'CHW_CONNECT',
  Host = 'HOST',
  OpenAccess = 'OPEN_ACCESS',
  WhiteLabel = 'WHITE_LABEL',
  WhiteLabelTemplate = 'WHITE_LABEL_TEMPLATE',
  FundaApp = 'FUNDA_APP',
}
