export const yesOrNoOptions = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
];

export const numberInputInvalidChars = ['-', '+', 'e', '.', ','];

export interface TeamMeetingsRouteState {
  clinicId: string;
}
