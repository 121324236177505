export const ColumnNames = {
  IdPassportEmail: 'ID/Passport/Email',
  Name: 'Name',
  Role: 'Role',
  Date: 'Date Added',
  Status: 'Status',
};

export const UserSearch = {
  SearchBy: '      Search by name, ID or cellphone...',
};
