export enum UsersRouteRedirectTypeEnum {
  chw = 'chw',
  teamLeads = 'team-leads',
  admins = 'Administrator',
  practitioner = 'Practitioner',
  principal = 'Principal',
  coach = 'coach',
}

export enum UsersRolesTypeEnum {
  chw = 'Community Health Worker',
  teamLeads = 'Team Lead',
  user = 'User',
  administrator = 'Administrator',
  practitioner = 'Practitioner',
  principal = 'Principal',
  coach = 'Coach',
}

export enum idTypeEnum {
  idNumber = 'idNumber',
  passport = 'Passport',
}
