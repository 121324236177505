export const ContentManagementTabs = {
  PROCESS: { id: 2, name: 'Progress' },
  PROGRAMMES: { id: 3, name: 'Programme' },
  //COMMUNITY: { id: 4, name: 'Community' },
  RESOURCES: { id: 4, name: 'Resources' },
};

export const ContentTypes = {
  COACHING_CIRCLE_TOPICS: 'CoachingCircleTopics',
  CONNECT: 'Connect',
  CONNECT_ITEM: 'ConnectItem',
  RESOURCE_LINK: 'ResourceLink',
  PROGRESS_TRACKING_SKILL: 'ProgressTrackingSkill',
  PROGRESS_TRACKING_CATEGORY: 'ProgressTrackingCategory',
  CONSENT: 'Consent',
  MORE_INFORMATION: 'MoreInformation',
  INFO_PAGES: 'Info Pages',
  TOPIC: 'Topic',
  THEME: 'Theme',
  ACTIVITY: 'Activity',
  CLASSROOMBUSINESSRESOURCE: 'ClassroomBusinessResource',
  STORY_BOOK: 'StoryBook',
};

export const ContentForms = {
  CONSENT_FORM: 'Consent Form',
  ACTIVITY_FROM: 'Activity Form',
};

export const CoachingCircleText = {
  START_DATE: 'startDate',
  START_DATE_SUB_HEADING:
    'This is the first day coaches will be able to use this coaching circle topic.',
  START_DATE_ALERT:
    'Editing the start date here will update the start date for all translation of this page.',
  END_DATE: 'endDate',
  END_DATE_SUB_HEADING:
    'This is the last day coaches will be able to use this coaching circle topic.  If you want this topic to be available indefinitely, leave this blank.',
  END_DATE_ALERT:
    'Editing the end date here will update the end date for all translation of this page.',
};
