import { QueryAllTeamLeadsArgs } from '@ecdlink/graphql';

export enum ConenctUsage {
  InvitationActive = 'Invitation active',
  InvitationExpired = 'Invitation expired',
  LastOnlineWithinPast6Months = 'Last online within past 6 months',
  LastOnlineOver6Months = 'Last online over 6 months ago',
  Removed = 'Removed (users who have been removed from CHW Connect)',
}

export interface TeamLeadsRouteState {
  queryVariables: QueryAllTeamLeadsArgs;
}
